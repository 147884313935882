
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    formFields: {
      username: '',
      password: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'token-auth/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.$store.commit('drfToken', responseData.body.drf_token);
        this.$store.commit('userData', responseData.body.user_data);
        this.$store.commit('loggedIn', true);

        if (responseData.body.user_data.signup_data !== undefined) {
          this.$router.push({ name: 'onboard' });
        } else {
          this.$router.push(typeof this.$route.query.next === 'string' ? this.$route.query.next : { name: 'feed' });
        }
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
