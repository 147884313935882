<template>
  <div class="horizontal-padding">
    <h1>Log In</h1>

    <alert
      v-if="formErrors.non_field_errors !== undefined"
      variant="danger"
      class="js-form-error"
      dismissible
      @dismissed="formErrors = {}"
    >
      {{ formErrors.non_field_errors[0] }}
    </alert>

    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="username">Username</label>
        <input
          id="username"
          v-model="formFields.username"
          type="text"
          required
        >
      </div>

      <div class="form-group">
        <label for="password">Password</label>
        <input
          id="password"
          v-model="formFields.password"
          type="password"
          required
        >
      </div>

      <button
        type="submit"
        class="btn btn-primary"
        :disabled="formSubmitting"
      >
        <template v-if="formSubmitting">
          Logging In
          <spinner />
        </template>

        <template v-else>
          Log In
        </template>
      </button>

      <p>
        <router-link :to="{ name: 'password_reset' }">
          Forgot username or password?
        </router-link>
      </p>

      <p>
        <router-link :to="{ name: 'signup' }">
          Sign Up
        </router-link>
      </p>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    formFields: {
      username: '',
      password: '',
    },
    formErrors: {} as Record<string, Array<string>>,
    formSubmitting: false,
  }),
  watch: {
    formErrors() {
      if (this.formErrors.non_field_errors !== undefined) {
        this.$nextTick(this.scrollToFirstError);
      }
    },
  },
  methods: {
    async submitForm() {
      this.formErrors = {};
      this.formSubmitting = true;

      const responseData = await this.api({
        url: 'token-auth/',
        method: 'POST',
        json: this.formFields,
      });

      this.formSubmitting = false;

      if (responseData.status === 200) {
        this.$store.commit('drfToken', responseData.body.drf_token);
        this.$store.commit('userData', responseData.body.user_data);
        this.$store.commit('loggedIn', true);

        if (responseData.body.user_data.signup_data !== undefined) {
          this.$router.push({ name: 'onboard' });
        } else {
          this.$router.push(typeof this.$route.query.next === 'string' ? this.$route.query.next : { name: 'feed' });
        }
      } else if (responseData.status === 400) {
        this.formErrors = responseData.body;
      } else {
        this.formErrors = {
          non_field_errors: [
            'Unable to communicate with the server. Please check your '
            + 'connection and try again.',
          ],
        };
      }
    },
  },
});
</script>

<style scoped>
  .alert {
    margin-right: auto;
    margin-left: auto;
    width: 350px;
    max-width: 100%;
  }

  form {
    margin: 0 auto 1rem;
    width: 290px;
  }

  .btn {
    margin-bottom: 1rem;
  }

  a {
    color: #fff;
  }
</style>
